export default {
  created() {
    this.restoreFieldsFromURL()
  },

  computed: {
    page: {
      get() {
        return parseInt(this.$route.query.page, 10) || 1
      },
      set(page) {
        if (this.page !== page) {
          this.$router.replace({ query: { ...this.$route.query, page } })
        }
      },
    },
  },

  methods: {
    addToSearchHistory(value, key) {
      const currentValue = this.$route.query[key]
      if (value === "" && currentValue === value) {
        return
      }
      if (String(currentValue) !== String(value)) {
        this.$router.replace({ query: { ...this.$route.query, [key]: value } })
      }
    },

    restoreFieldsFromURL() {
      const params = this.$route.query

      Object.keys(params).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(this, key)) {
          const value = params[key]

          if (value === "1" || value === "0") {
            this[key] = parseInt(value, 10)
          } else {
            this[key] = value
          }
        }
      })
    },

    clearParams(path = "/") {
      const targetRoute = { path, query: {} }

      // Check if the target route is different from the current route
      if (this.$route.fullPath !== this.$router.resolve(targetRoute).href) {
        this.$router.push(targetRoute)
      }
    },
  },
}
